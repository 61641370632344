import { BASE_URL } from '.';

export const api_getProducts = async (pageNo?: number, pageSize?: number) => {
	const page_number = pageNo || 1;
	const page_size = pageSize || 10;
	const uri = `${BASE_URL}/products/getAll?page_number=${page_number}&page_size=${page_size}`;
	console.log(uri, 'jiji');

	const response = await fetch(uri, {
		method: 'GET',
	}).then((res) => res.json());
	return response;
};

export const api_getProductsWithFilters = async (
	pageNo?: number,
	pageSize?: number,
	name?: string,
	categoryId?: string,
) => {
	const page_number = pageNo || 1;
	const page_size = pageSize || 10;

	let uri = `${BASE_URL}/products/searchFilter/?page_number=${page_number}&page_size=${page_size}`;
	if (name) {
		uri = uri + `&name=${name}`;
	}
	if (categoryId) {
		uri = uri + `&category=${categoryId}`;
	}
	console.log(uri, 'URI-->');

	const response = await fetch(uri, {
		method: 'GET',
	}).then((res) => res.json());
	return response;
};

export const api_getProductById = async (id: string, userId: string) => {
	const uri = `${BASE_URL}/products/getById/${id}/${userId}`;
	const response = await fetch(uri, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res) => res.json());
	return response;
};

export const api_getProductByCategory = async (
	categoryId: string,
	pageNo: number,
	pageSize: number,
) => {
	const uri = `${BASE_URL}/products/getByCategory?page_size=${pageSize}&page_number=${pageNo}&categoryId=${categoryId}`;
	const response = await fetch(uri, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res) => res.json());
	return response;
};

export const api_getProductByName = async (value: string, pageNo: number, pageSize: number) => {
	const uri = `${BASE_URL}/products/search?page_size=${pageSize}&page_number=${pageNo}&value=${value}`;
	console.log(uri);
	const response = await fetch(uri, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res) => res.json());
	return response;
};
export const api_createProduct = async (payload: FormData) => {
	const uri = `${BASE_URL}/products/create`;
	console.log(uri);
	const response = await fetch(uri, {
		method: 'POST',
		headers: {},
		body: payload,
	}).then((res) => res.json());
	return response;
};

export const api_editProduct = async (payload: FormData, productId: string) => {
	const uri = `${BASE_URL}/products/update/${productId}`;
	console.log(uri);
	const response = await fetch(uri, {
		method: 'PUT',
		headers: {},
		body: payload,
	}).then((res) => res.json());
	return response;
};

export const api_deleteProduct = async (productId: string) => {
	const uri = `${BASE_URL}/products/remove/${productId}`;
	console.log(uri);
	const response = await fetch(uri, {
		method: 'DELETE',
		headers: {},
	}).then((res) => res.json());
	return response;
};
