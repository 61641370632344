import { BASE_URL } from '.';

export const api_updateProfile = async (payload: any, token: string, authId: string) => {
	console.log(token, payload, authId);
	const uri = `${BASE_URL}/users/update/${authId}`;

	const response = await fetch(uri, {
		method: 'PUT',
		headers: {
			'Content-Type': 'multipart/form-data',
			'x-access-token': token,
		},
		body: payload,
	}).then((res) => res.json());

	return response;
};

export const api_getUserById = async (token: string, authId: string) => {
	const uri = `${BASE_URL}/users/getById/${authId}`;
	const response = await fetch(uri, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	}).then((res) => res.json());
	console.log(response);
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};

export const api_getAllUsers = async (token: string, pageNo: number, pageSize: number) => {
	const uri = `${BASE_URL}/users/getAll?pageNo=${pageNo}&pageSize=${pageSize}`;
	const response = await fetch(uri, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	}).then((res) => res.json());
	console.log(response);
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};

export const api_userCreate = async (paylod: any) => {
	const uri = `${BASE_URL}/users/create`;
	const response = await fetch(uri, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(paylod),
	}).then((res) => res.json());
	console.log(response);
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};

export const api_userProfileUpdate = async (userId: string, token: string, formData: FormData) => {
	const uri = `${BASE_URL}/users/uploadProfile/${userId}`;
	const response = await fetch(uri, {
		method: 'PUT',
		headers: {
			'x-access-token': token,
		},
		body: formData,
	}).then((res) => res.json());
	console.log(response);
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};
