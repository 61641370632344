import React, { useEffect, useState } from 'react';

import { api_createCategories, api_getCategories } from '../../../api/category';
import { useNavigate } from 'react-router-dom';
import { api_createProduct } from '../../../api/product';

import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { SELECT_OPTIONS } from '../../documentation/forms/SelectPage';
import { myToastWarning } from '../../../helpers/myToast';
import { toast } from 'react-hot-toast';

export const SelectAsyncPaginate = ({ value, onChange }: { onChange: any; value: any }) => {
	// const [selectedCat, setSelectedCat] = useState<any>(null);

	const loadOptions = async (
		searchQuery: any,
		loadedOptions: any,
		{ page }: { page: number },
	) => {
		const res = await api_getCategories(page, 10);
		console.log(res);
		return {
			options: res.data,
			hasMore: res.data.length >= 1,
			additional: {
				page: page + 1,
			},
		};
	};

	// const onChange = (option: any) => {
	// 	console.log(option);
	// 	setSelectedCat(option);
	// };

	return (
		<AsyncPaginate
			value={value}
			// @ts-ignore
			loadOptions={loadOptions}
			getOptionValue={(option) => option._id}
			getOptionLabel={(option) => option.title}
			onChange={onChange}
			isSearchable={false}
			placeholder='Select Category'
			additional={{
				page: 1,
			}}
		/>
	);
};

const AddProduct = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);
	const [name, setName] = useState('');
	const [quantity, setQuantity] = useState<string | number>('1');
	const [price, setPrice] = useState<string | number>('1');
	const [localPrice, setLocalPrice] = useState<string | number>('1');
	const [des, setDes] = useState('');
	// const [address, setAddres] = useState('');
	const [selectedCategory, setSelectedCategory] = useState<any>(null);

	console.log(selectedCategory, 'selectedCategory');
	const navigate = useNavigate();
	const create = async () => {
		console.log(image);
		if (image === null) {
			myToastWarning('Please Pick Image');
			return;
		}
		if (!quantity) {
			myToastWarning('Please Enter Quantity');
			return;
		}
		if (!name) {
			myToastWarning('Please Enter Name');
			return;
		}
		if (!localPrice) {
			myToastWarning('Please Enter Br Price');
			return;
		}
		if (!price) {
			myToastWarning('Please Enter USD Price');
			return;
		}

		try {
			setLoading(true);
			const payload = new FormData();
			payload.append('name', name);
			payload.append('description', des);
			payload.append('address', '');

			// @ts-ignore
			payload.append('quantity', quantity);

			// LOCAL PRICE
			// @ts-ignore
			payload.append('local_price_amount', Number(localPrice));
			payload.append('local_price_currency', 'local');
			payload.append('local_price_prefix', 'Br');
			// GLOBAL PRICE
			// @ts-ignore
			payload.append('price_amount', Number(price));
			payload.append('price_currency', 'globaly');
			payload.append('price_prefix', '$');

			if (selectedCategory) {
				payload.append('category', selectedCategory._id);
			}
			payload.append('image', image);
			const res = await api_createProduct(payload);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setTimeout(() => {
					navigate(-1);
				}, 100);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Add Product</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Name
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Price In Dollar
				</label>
				<input
					value={price}
					type='number'
					onChange={(e) => setPrice(Number(e.target.value))}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Price In Ethiopian Birr
				</label>
				<input
					value={localPrice}
					type='number'
					onChange={(e) => setLocalPrice(Number(e.target.value))}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Qty
				</label>
				<input
					value={quantity}
					type='number'
					onChange={(e) => setQuantity(Number(e.target.value))}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='formFileSm' className='form-label'>
					Product Image
				</label>
				<input
					onChange={(event) => {
						// @ts-ignore

						console.log(event.target.files[0]);
						// @ts-ignore

						setImage(event.target.files[0]);
					}}
					style={{ width: '400px' }}
					className='form-control form-control-sm'
					id='formFileSm'
					type='file'
				/>
			</div>
			<div className='mb-3' style={{ width: '400px' }}>
				<label htmlFor='formFileSm' className='form-label'>
					Product Category
				</label>

				<SelectAsyncPaginate onChange={setSelectedCategory} value={selectedCategory} />
			</div>

			{/* <div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Address
				</label>
				<textarea
					value={address}
					onChange={(e) => setAddres(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div> */}
			<div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Description
				</label>
				<textarea
					value={des}
					onChange={(e) => setDes(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div>
			<button
				onClick={create}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default AddProduct;
