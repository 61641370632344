import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { api_getCategories } from '../../../api/category';
import { AsyncPaginate } from 'react-select-async-paginate';
import React, { useState } from 'react';
type Props = {
	show: boolean;
	handleClose: () => void;
	setCategoryFilter: any;
	categoryFilter: any;
	handleReset: any;
};

// sort ->
// by name  -> asd desc
// by category ->
// by createdAt -> asd desc

export const SelectAsyncPaginate = ({ value, onChange }: { onChange: any; value: any }) => {
	const loadOptions = async (
		searchQuery: any,
		loadedOptions: any,
		{ page }: { page: number },
	) => {
		console.log(page, 'page');
		const res = await api_getCategories(page, 10);
		console.log(res);
		return {
			options: page === 1 ? [{ _id: '0', title: 'ALL' }, ...res.data] : res.data,
			hasMore: res.data.length >= 1,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<AsyncPaginate
			value={value}
			// @ts-ignore
			loadOptions={loadOptions}
			getOptionValue={(option) => option._id}
			getOptionLabel={(option) => option.title}
			onChange={onChange}
			isSearchable={false}
			placeholder='Select Category'
			additional={{
				page: 1,
			}}
		/>
	);
};

function FilterModal({ show, handleClose, setCategoryFilter, categoryFilter, handleReset }: Props) {
	const [selectedCategory, setSelectedCategory] = useState(categoryFilter.category);

	React.useEffect(() => {
		if (selectedCategory?._id === '0') {
			setCategoryFilter({
				enable: false,
				category: null,
			});
		} else {
			setCategoryFilter({
				enable: true,
				category: selectedCategory?._id,
			});
		}
	}, [selectedCategory]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			keyboard={false}
			size='sm'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title>Product Filter</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='mb-3'>
					<label htmlFor='formFileSm' className='form-label'>
						Product Category
					</label>

					<SelectAsyncPaginate onChange={setSelectedCategory} value={selectedCategory} />
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleClose}>
					Close
				</Button>
				<Button variant='primary' onClick={handleReset}>
					Reset
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default FilterModal;
