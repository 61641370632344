import { useState } from 'react';
import { api_createCategories } from '../../../api/category';
import { useNavigate } from 'react-router-dom';
import { api_addBanner, api_deleteBanner } from '../../../api/banner';
import { toast } from 'react-hot-toast';

const AddBanner = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);

	const navigate = useNavigate();
	const create = async () => {
		console.log(image);
		if (image === null) {
			alert('Please Pick Image');
			return;
		}

		try {
			setLoading(true);
			const payload = new FormData();
			payload.append('image', image);
			const res = await api_addBanner(payload);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				navigate(-1);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Add Banner</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='formFileSm' className='form-label'>
					Banner Image
				</label>
				<input
					onChange={(event) => {
						// @ts-ignore

						console.log(event.target.files[0]);
						// @ts-ignore

						setImage(event.target.files[0]);
					}}
					style={{ width: '400px' }}
					className='form-control form-control-sm'
					id='formFileSm'
					type='file'
				/>
			</div>

			<button
				onClick={create}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default AddBanner;
