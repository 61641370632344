import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { create_Tags, getAll_tags } from './TagsApi';

const initialState = {
	all_tags: [],
};
//********************** */ get all
const fetch_all_tags = createAsyncThunk('tags/fetch_all_tags', async (modeid: any) => {
	try {
		const response = await getAll_tags(modeid);

		return response;
	} catch (error) {
		console.log(error);
	}
});

const add_Tags = createAsyncThunk('tags/add_Tags', async (data: any) => {
	try {
		const response = await create_Tags(data);
	} catch (error) {
		console.log(error);
	}
});
//  const search_addon =createAsyncThunk('Addon/search_addon',async(name:any)=>{
// 	try {
// 		const response=await AddonSearch(name)
// 		console.log(response)
// 		return response
// 	} catch (error) {
// 		console.log(error)
// 	}
// })
const TagsSlice = createSlice({
	name: 'Tags',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetch_all_tags.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_tags = action.payload;
		});
	},
});

export { fetch_all_tags, add_Tags };
export default TagsSlice.reducer;
