import { BASE_URL } from '.';

export const api_getCategories = async (pageNo?: number, pageSize?: number) => {
	const page_number = pageNo || 1;
	const page_size = pageSize || 10;
	const uri = `${BASE_URL}/category/getAll?page_number=${page_number}&page_size=${page_size}`;
	const response = await fetch(uri, {
		method: 'GET',
	}).then((res) => res.json());
	return response;
};

export const api_createCategories = async (formData: any) => {
	const uri = `${BASE_URL}/category/create`;
	const response = await fetch(uri, {
		method: 'POST',
		body: formData,
	}).then((res) => res.json());
	return response;
};

export const api_editCategories = async (categoryId: string, formData: any) => {
	const uri = `${BASE_URL}/category/update/${categoryId}`;
	const response = await fetch(uri, {
		method: 'PUT',
		body: formData,
	}).then((res) => res.json());
	return response;
};

export const api_deleteCategory = async (categoryId: string) => {
	const uri = `${BASE_URL}/category/remove/${categoryId}`;
	const response = await fetch(uri, {
		method: 'DELETE',
	}).then((res) => res.json());
	return response;
};
