import { BASE_URL } from '.';

export const api_sendOtp = async (payload: any) => {
	const uri = `${BASE_URL}/users/loginOtp`;
	const response = await fetch(uri, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	}).then((res) => res.json());
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};
export const api_verifyOtp = async (payload: any) => {
	const uri = `${BASE_URL}/users/verifyLoginOtp`;
	const response = await fetch(uri, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	}).then((res) => res.json());
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};

export const api_adminLogin = async (email: string, password: string) => {
	const uri = `${BASE_URL}/users/adminLogin`;
	const response = await fetch(uri, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email,
			password,
		}),
	}).then((res) => res.json());
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};

export const api_deleteUser = async (token: string, userID: string) => {
	const uri = `${BASE_URL}/users/delete/${userID}`;
	const response = await fetch(uri, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	}).then((res) => res.json());
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};
export const api_updateUser = async (token: string, userID: string, payload: any) => {
	const uri = `${BASE_URL}/users/update/${userID}`;
	const response = await fetch(uri, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
		body: JSON.stringify(payload),
	}).then((res) => res.json());
	if (!response.isSuccess) {
		throw new Error(response.error || 'something went wrong!');
	}
	return response;
};
