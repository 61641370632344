import { configureStore } from "@reduxjs/toolkit";
import Customer_slice from "./pages/USERSCRUDS/CUSTOMERCRUD/slice/CustomerSlice"
import driverSlice from "./pages/USERSCRUDS/DRIVERCRUD/DriverSlice/DriverSlice";
import ResortSlice from "./pages/USERSCRUDS/RESORTCRUD/resortslice/ResortSlice";
import RestaurantSlice from "./pages/USERSCRUDS/RESTAURANT/RestaurantSlice/RestaurantSlice";
import OrderSlice from "./pages/USERSCRUDS/Order/OrderSlice/OrderSlice";
import AddOn_slice from "./pages/USERSCRUDS/Menu/AddOnn/AddOnSlice/AddOn_slice";
import itemsSlice from "./pages/USERSCRUDS/Menu/Items/ItemsSlice/ItemSlice"
import Category_slice from "./pages/USERSCRUDS/Menu/Category/Category_slice/Category_slice";
import TagsSlice from "./pages/USERSCRUDS/Menu/Tags/TagsSlice/TagsSlice";
import modeSlice from "./pages/USERSCRUDS/Mode/ModeSlice/ModeSlice";


 const store=configureStore({
    reducer:{
      customer:Customer_slice,
       driver:driverSlice,
       resort:ResortSlice,
       restaurant:RestaurantSlice,
       order:OrderSlice,
       addon:AddOn_slice,
       items:itemsSlice,
       category:Category_slice,
       tags:TagsSlice,
       mode:modeSlice,
    }
})

export default store