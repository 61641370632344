import { Dropdown } from 'react-bootstrap';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useEffect, useState } from 'react';
import { getFirstLetter } from '../../../helpers/helpers';
import Avatar from '../../../components/Avatar';
import { getImageUrl } from '../../../api';
import { api_deleteCategory, api_getCategories } from '../../../api/category';
import { useNavigate } from 'react-router-dom';
import { api_deleteBanner, api_getBanners } from '../../../api/banner';
import { toast } from 'react-hot-toast';
const PAGE_SIZE = 100;
const Banner = () => {
	const [bannerList, setBannerList] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [deletLoadingIndex, setDeletLoadingIndex] = useState<null | number>(null);
	const navigate = useNavigate();
	const getBanners = async (page_no: number) => {
		try {
			setLoading(true);
			const res = await api_getBanners(page_no, PAGE_SIZE);
			console.log(res);
			if (res.isSuccess) {
				setBannerList(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteCategory = async (id: string, index: number) => {
		setDeletLoadingIndex(index);
		try {
			const res = await api_deleteBanner(id);
			console.log(res);
			if (res.isSuccess) {
				// alert(res.message || 'success');
				toast.success(res.message || 'success');
				setBannerList(
					bannerList.filter((item: any) => {
						return item._id !== id;
					}),
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setDeletLoadingIndex(null);
		}
	};

	useEffect(() => {
		getBanners(1);
	}, []);

	return (
		<PageWrapper title={'Banner'}>
			<div style={{ marginTop: '20px' }}>
				<SubHeader>
					<SubHeaderLeft>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<Icon icon='Search' size='2x' color='primary' />
						</label>
						<Input
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent'
							placeholder='Search order...'
						/>
					</SubHeaderLeft>
					<SubHeaderRight>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									icon='FilterAlt'
									color='dark'
									isLight
									className='btn-only-icon position-relative'>
									<Popovers desc='Filtering applied' trigger='hover'>
										<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
											<span className='visually-hidden'>
												there is filtering
											</span>
										</span>
									</Popovers>
								</Button>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg'>
								<div className='container py-2'>
									<div className='row g-3'>
										{/* <FormGroup label='Balance' className='col-12'>
                                            <InputGroup>
                                                <Input
                                                    id='minPrice'
                                                    ariaLabel='Minimum price'
                                                    placeholder='Min.'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.minPrice}
                                                />
                                                <InputGroupText>to</InputGroupText>
                                                <Input
                                                    id='maxPrice'
                                                    ariaLabel='Maximum price'
                                                    placeholder='Max.'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.maxPrice}
                                                />
                                            </InputGroup>
                                        </FormGroup> */}
										<FormGroup label='Order Filter' className='col-12'>
											<ChecksGroup>
												<Checks
													key='1'
													id=''
													label='Active'
													name='Active'
													value={'true'}
													//  onChange={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('true')}
												/>

												<Checks
													key='2'
													id=''
													label='Price(low to high)'
													name='Payment'
													value={'false'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('false')}
												/>
												<Checks
													key='2'
													id=''
													label='Price(high to low)'
													name='Payment'
													value={'false'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('false')}
												/>
												<Checks
													key='3'
													id=''
													label='Ratings'
													name='Ratings'
													value={'Ratings'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('ascending')}
												/>
												{/* <Checks
                                                     key='4'
                                                     id=""
                                                     label="Dscending"
                                                     name="Dscending"
                                                     value = {'dscending'}
                                                    //  onClick={(e)=>handleFilterApi(e)}
                                                    //  checked={selectedFilters.includes('dscending')}
    
    
                                                 /> */}
											</ChecksGroup>
										</FormGroup>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
						<SubheaderSeparator />
					</SubHeaderRight>
				</SubHeader>
			</div>
			<Card stretch>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle>All Banner</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							icon='PersonAdd'
							onClick={() => navigate('/add/banner')}
							color='primary'
							isLight={true}>
							New Banner
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody isScrollable className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>Image</th>
								<th>ACTION</th>
							</tr>
						</thead>
						<tbody>
							{bannerList?.map((item: any, index: any) => {
								return (
									<tr key={index}>
										<td>
											<img
												src={getImageUrl(item?.image)}
												style={{
													width: 50,
													height: 50,
													borderRadius: 50,
													objectFit: 'cover',
												}}
											/>
										</td>

										<td>
											<span
												onClick={() => deleteCategory(item._id, index)}
												style={{ margin: 10, cursor: 'pointer' }}>
												{deletLoadingIndex === index
													? 'Loading..'
													: 'DELETE'}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default Banner;
