import { BASE_URL } from '.';

export const api_getCateringBanners = async (pageNo?: number, pageSize?: number) => {
	const uri = `${BASE_URL}/cateBanner/getAll?page_size=${pageSize}&page_number=${pageNo}`;
	const response = await fetch(uri, {
		method: 'GET',
	}).then((res) => res.json());
	return response;
};

export const api_addCateringBanner = async (payload: FormData) => {
	const uri = `${BASE_URL}/cateBanner/create`;
	const response = await fetch(uri, {
		method: 'POST',
		body: payload,
	}).then((res) => res.json());
	return response;
};

export const api_deleteCateringBanner = async (bannerId: string) => {
	const uri = `${BASE_URL}/cateBanner/remove/${bannerId}`;
	const response = await fetch(uri, {
		method: 'DELETE',
	}).then((res) => res.json());
	return response;
};
