import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ResortGetAll, ResortAdd } from './ResortApi';

interface Resort {
	fullname: string;
}

interface ResortState {
	allResort: Resort[];
	currentResort: Resort[];
}

const initialState: ResortState = {
	allResort: [],
	currentResort: [],
};

// local storage
const token: string | null = localStorage.getItem('token');
console.log(token);

// get
const fetchResortAll = createAsyncThunk('resort/fetchResortAll', async () => {
	const response = await ResortGetAll(token as string);
	console.log(response);
	return response; // Assuming the response is an array of resorts
});

// add
const addResortAll = createAsyncThunk('resort/addResortAll', async (data: any) => {
	try {
		const formData: any = new FormData();
		// Append all the required data to formData
		// ...

		const response = await ResortAdd(token as string, formData);
		return response;
	} catch (error) {}
});

const ResortSlice = createSlice({
	name: 'ResortSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchResortAll.fulfilled, (state, action: any) => {
			// state.allResort.push(...action.payload);
			state.allResort = action.payload;
		});
		// builder.addCase(addResortAll.fulfilled, (state, action: any) => {
		//   state.allResort.push(...action.payload);
		// });
	},
});

export default ResortSlice.reducer;
export { fetchResortAll, addResortAll };
