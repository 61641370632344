import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddonSearch, create_Addon, getAll_addOn } from './AddOnApi';

const initialState = {
	all_AddOns: [],
};
//********************** */ get all
const fetch_allAddons = createAsyncThunk('Addon/fetch_allAddons', async (modeid: any) => {
	try {
		const response = await getAll_addOn(modeid);

		return response;
	} catch (error) {
		console.log(error);
	}
});

// *********add addom**********************

const add_Addons = createAsyncThunk('Addon/fetch_allAddons', async (data: any) => {
	try {
		const response = await create_Addon(data);
	} catch (error) {
		console.log(error);
	}
});
const search_addon = createAsyncThunk('Addon/search_addon', async (name: any) => {
	try {
		const response = await AddonSearch(name);
		console.log(response);
		return response;
	} catch (error) {
		console.log(error);
	}
});
const AddOn_slice = createSlice({
	name: 'Addon',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetch_allAddons.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_AddOns = action.payload;
		});
		builder.addCase(search_addon.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_AddOns = action.payload;
		});
	},
});

export { fetch_allAddons, add_Addons, search_addon };
export default AddOn_slice.reducer;
