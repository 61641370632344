import { BASE_URL } from '.';

export const api_getBanners = async (pageNo?: number, pageSize?: number) => {
	const uri = `${BASE_URL}/banners/getAll?page_size=${pageSize}&page_number=${pageNo}`;
	const response = await fetch(uri, {
		method: 'GET',
	}).then((res) => res.json());
	return response;
};

export const api_addBanner = async (payload: FormData) => {
	const uri = `${BASE_URL}/banners/create`;
	const response = await fetch(uri, {
		method: 'POST',
		body: payload,
	}).then((res) => res.json());
	return response;
};

export const api_deleteBanner = async (bannerId: string) => {
	const uri = `${BASE_URL}/banners/remove/${bannerId}`;
	const response = await fetch(uri, {
		method: 'DELETE',
	}).then((res) => res.json());
	return response;
};
