import { useState } from 'react';
import { api_createCategories, api_editCategories } from '../../../api/category';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { myToastWarning } from '../../../helpers/myToast';

const EditCategory = () => {
	const category = useLocation().state;
	const [loading, setLoading] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);
	const [name, setName] = useState(category.name || '');
	const [title, setTitle] = useState(category.title || '');
	const [des, setDes] = useState(category.description || '');
	const navigate = useNavigate();
	const edit = async () => {
		console.log(image);
		if (image === null) {
			myToastWarning('Please Pick Image');
			return;
		}
		if (!title) {
			myToastWarning('Please Enter Title');
			return;
		}
		if (!name) {
			myToastWarning('Please Enter Name');
			return;
		}
		try {
			setLoading(true);
			const payload = new FormData();
			payload.append('name', name);
			payload.append('title', title);
			payload.append('description', des);
			payload.append('image', image);
			const res = await api_editCategories(category._id, payload);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setTimeout(() => {
					navigate(-1);
				}, 100);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Edit Category</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Name
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Title
				</label>
				<input
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='formFileSm' className='form-label'>
					Product Image
				</label>
				<input
					onChange={(event) => {
						// @ts-ignore

						console.log(event.target.files[0]);
						// @ts-ignore

						setImage(event.target.files[0]);
					}}
					style={{ width: '400px' }}
					className='form-control form-control-sm'
					id='formFileSm'
					type='file'
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Description
				</label>
				<textarea
					value={des}
					onChange={(e) => setDes(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div>
			<button
				onClick={edit}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default EditCategory;
