import { Dropdown } from 'react-bootstrap';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useContext, useEffect, useState } from 'react';
import { api_getProducts } from '../../../api/product';
import { getFirstLetter } from '../../../helpers/helpers';
import Avatar from '../../../components/Avatar';
import { getImageUrl } from '../../../api';
import { api_getAllUsers } from '../../../api/user';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { api_deleteAddress } from '../../../api/address';
import { api_deleteUser } from '../../../api/auth';
const PAGE_SIZE = 10;
const AllUsers = () => {
	const [users, setUsers] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [deletLoadingIndex, setDeletLoadingIndex] = useState<null | number>(null);
	const [pageNo, setPageNo] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const navigate = useNavigate();
	const { userData } = useContext(AuthContext) as any;

	const getProduct = async (page_no: number) => {
		try {
			setLoading(true);
			const res = await api_getAllUsers(userData.token, page_no, PAGE_SIZE);
			console.log(res);
			if (res.isSuccess) {
				if (!res.data.items.length) {
					setHasMore(false);
					return;
				}
				setUsers([...users, ...res.data.items]);
				setPageNo((s) => s + 1);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const onLoadMore = () => {
		if (hasMore) {
			getProduct(pageNo);
		}
	};

	const handleDeleteUser = async (userId: string, index: number) => {
		try {
			setDeletLoadingIndex(index);
			const res = await api_deleteUser(userData.token, userId);
			console.log(res);
			if (res.isSuccess) {
				setUsers(users.filter((i: any) => i._id !== userId));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setDeletLoadingIndex(null);
		}
	};
	console.log({ users });
	useEffect(() => {
		getProduct(pageNo);
	}, []);

	return (
		<PageWrapper title={'All Users'}>
			<div style={{ marginTop: '20px' }}>
				<SubHeader>
					<SubHeaderLeft>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<Icon icon='Search' size='2x' color='primary' />
						</label>
						<Input
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent'
							placeholder='Search order...'
						/>
					</SubHeaderLeft>
					<SubHeaderRight>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									icon='FilterAlt'
									color='dark'
									isLight
									className='btn-only-icon position-relative'>
									<Popovers desc='Filtering applied' trigger='hover'>
										<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
											<span className='visually-hidden'>
												there is filtering
											</span>
										</span>
									</Popovers>
								</Button>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg'>
								<div className='container py-2'>
									<div className='row g-3'>
										{/* <FormGroup label='Balance' className='col-12'>
										<InputGroup>
											<Input
												id='minPrice'
												ariaLabel='Minimum price'
												placeholder='Min.'
												onChange={formik.handleChange}
												value={formik.values.minPrice}
											/>
											<InputGroupText>to</InputGroupText>
											<Input
												id='maxPrice'
												ariaLabel='Maximum price'
												placeholder='Max.'
												onChange={formik.handleChange}
												value={formik.values.maxPrice}
											/>
										</InputGroup>
									</FormGroup> */}
										<FormGroup label='Order Filter' className='col-12'>
											<ChecksGroup>
												<Checks
													key='1'
													id=''
													label='Active'
													name='Active'
													value={'true'}
													//  onChange={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('true')}
												/>

												<Checks
													key='2'
													id=''
													label='Price(low to high)'
													name='Payment'
													value={'false'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('false')}
												/>
												<Checks
													key='2'
													id=''
													label='Price(high to low)'
													name='Payment'
													value={'false'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('false')}
												/>
												<Checks
													key='3'
													id=''
													label='Ratings'
													name='Ratings'
													value={'Ratings'}
													//  onClick={(e)=>handleFilterApi(e)}
													//  checked={selectedFilters.includes('ascending')}
												/>
												{/* <Checks
												 key='4'
												 id=""
												 label="Dscending"
												 name="Dscending"
												 value = {'dscending'}
												//  onClick={(e)=>handleFilterApi(e)}
												//  checked={selectedFilters.includes('dscending')}
											 /> */}
											</ChecksGroup>
										</FormGroup>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
						<SubheaderSeparator />
					</SubHeaderRight>
				</SubHeader>
			</div>
			<Card stretch>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle>All Users</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() => navigate('/add/users')}
							icon='PersonAdd'
							color='primary'
							isLight={true}>
							New User
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody isScrollable className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<td style={{ width: 60 }} />
								<th>Name </th>
								<th>Email</th>
								<th>Role</th>
								<th>Gender</th>
								<th>Phone No</th>
								<th>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{users?.map((item: any, index: any) => {
								return (
									<tr key={index}>
										<td>
											<div className='flex-shrink-0'>
												<div
													className='ratio ratio-1x1 me-3'
													style={{ width: 48 }}>
													<div
														className='ratio ratio-1x1 me-3 '
														style={{ width: 50 }}>
														{item?.avatar ? (
															<img
																src={getImageUrl(item?.avatar)}
																style={{
																	width: 50,
																	height: 50,
																	borderRadius: 50,
																	objectFit: 'cover',
																}}
															/>
														) : (
															<div>
																<span className='fw-bold'>
																	{item?.name
																		? getFirstLetter(item.name)
																		: ''}
																</span>
															</div>
														)}
													</div>
												</div>
											</div>
										</td>

										<td>
											<div className='d-flex'>
												<div className='flex-grow-1'>
													<div className='fs-6 fw-bold'>{item?.name}</div>
												</div>
											</div>
										</td>

										<td>{item?.email}</td>
										<td>{item?.role}</td>
										<td>{item?.gender}</td>

										<td>{item?.phone_number}</td>
										<td>
											<span
												onClick={() =>
													navigate('/edit/user', { state: item })
												}
												style={{ margin: 10, cursor: 'pointer' }}>
												EDIT
											</span>
											<span
												onClick={() => {
													handleDeleteUser(item._id, index);
												}}
												style={{ margin: 10, cursor: 'pointer' }}>
												{deletLoadingIndex === index
													? 'Loading..'
													: 'DELETE'}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						{hasMore && (
							<button
								style={{
									border: 'none',
									padding: '10px',
									borderRadius: '10px',
								}}
								onClick={onLoadMore}>
								Load More...
							</button>
						)}
					</div>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default AllUsers;
