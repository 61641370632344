import { BASE_URL } from '../../../../../ApiBaseUrl';

//**************************************getAll_addOn****************************** */

export const getAll_addOn = async (modeid: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = await fetch(
			`${BASE_URL}/addon/getAll/${modeid}?page_no=1&page_size=100`,
			options,
		);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error, 'error');
	}
};

// *******************************add add on**************************************

export const create_Addon = async (data: any) => {
	const formData: any = new FormData();

	formData.append('is_activated', data.is_activated);

	formData.append('addon', JSON.stringify(data.addon));

	formData.append('image', data.image);
	formData.append('itemId', data.itemId);
	formData.append('mode', data.mode);

	const options = {
		method: 'POST',
		// headers:{
		//     'Content-Type':'multipart/form-data'
		// },
		body: formData,
	};
	console.log(JSON.stringify(formData));
	try {
		const response = await fetch(`${BASE_URL}addon/create`, options);
	} catch (error) {
		console.log('error', error);
	}
};

export const AddonSearch = async (name: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}/addon/search?name=${name}`, options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error);
	}
};
