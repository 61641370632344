import { useContext, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { api_userCreate, api_userProfileUpdate } from '../../../api/user';
import { api_updateUser } from '../../../api/auth';
import AuthContext from '../../../contexts/authContext';
import { toast } from 'react-hot-toast';
import { myToastWarning } from '../../../helpers/myToast';

const GENDERS = ['male', 'female'];
const ROLE = ['customer', 'admin'];

const EditUser = () => {
	const user = useLocation().state;
	const { userData } = useContext(AuthContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [loading2, setLoading2] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);
	const [name, setName] = useState(user.name);
	const [gender, setGender] = useState(user.sex || GENDERS[0]);
	const [role, setRole] = useState(user.role || ROLE[0]);
	const [phone, setPhone] = useState(user.phone_number || '');
	const [email, setEmail] = useState(user.email || '');
	const [address, setAddress] = useState(user.address || '');
	const navigate = useNavigate();

	const uploadProfile = async () => {
		try {
			setLoading2(true);
			const formData = new FormData();
			formData.append('image', image);
			console.log(userData, user);
			// @ts-ignore
			const res = await api_userProfileUpdate(user._id, userData.token, formData);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setImage(null);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading2(false);
		}
	};
	const edit = async () => {
		if (!email) {
			myToastWarning('Please Enter email');
			return;
		}
		if (!name) {
			myToastWarning('Please Enter Name');
			return;
		}

		try {
			setLoading(true);
			const payload = {
				name: name,
				email: email,

				role: role,
				// dob: 'string',
				gender: gender,
				phone_number: phone,
				address: address,
				is_activited: true,
			};
			// @ts-ignore
			const res = await api_updateUser(userData.token, user._id, payload);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setTimeout(() => {
					navigate(-1);
				}, 100);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Edit User</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Name
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Email
				</label>
				<input
					value={email}
					type='email'
					onChange={(e) => setEmail(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Phone
				</label>
				<input
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>

			<div className='mb-3'>
				<label htmlFor='formFileSm' className='form-label'>
					Profile Image
				</label>
				<input
					onChange={(event) => {
						// @ts-ignore
						console.log(event.target.files[0]);
						// @ts-ignore
						setImage(event.target.files[0]);
					}}
					style={{ width: '400px' }}
					className='form-control form-control-sm'
					id='formFileSm'
					type='file'
				/>

				{image !== null && (
					<button
						style={{ margin: '2px' }}
						onClick={uploadProfile}
						type='button'
						className='btn btn-lg btn-primary'
						disabled={loading2}>
						{loading2 ? 'Loading..' : 'Upload'}
					</button>
				)}
			</div>

			<div style={{ width: '400px' }}>
				<div className='mb-3'>
					<label htmlFor='exampleFormControlInput1' className='form-label'>
						Gender
					</label>
					<select
						onChange={(e) => setGender(e.target.options[e.target.selectedIndex].value)}
						className='form-select'
						aria-label='Default select example'>
						<option disabled>Choose Gender</option>

						{GENDERS.map((i, index) => {
							return (
								<option key={index} value={i}>
									{i}
								</option>
							);
						})}
					</select>
				</div>

				<div className='mb-3'>
					<label htmlFor='exampleFormControlInput1' className='form-label'>
						Role
					</label>
					<select
						onChange={(e) => setRole(e.target.options[e.target.selectedIndex].value)}
						className='form-select'
						aria-label='Default select example'>
						<option disabled>Choose Role</option>
						{ROLE.map((r, index) => (
							<option key={index} value={r}>
								{r}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Address
				</label>
				<textarea
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div>
			<button
				onClick={edit}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default EditUser;
