import { Dropdown, Modal } from 'react-bootstrap';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useEffect, useRef, useState } from 'react';
import {
	api_deleteProduct,
	api_getProducts,
	api_getProductsWithFilters,
} from '../../../api/product';
import { getFirstLetter } from '../../../helpers/helpers';
import Avatar from '../../../components/Avatar';
import { getImageUrl } from '../../../api';
import { useNavigate } from 'react-router-dom';
import FilterModal, { SelectAsyncPaginate } from './FilterModal';
import { useSet } from 'react-use';
const PAGE_SIZE = 5;

const Item = ({ index, item, removeProductFromTable }: any) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleDeleteProduct = async (pId: string, index: number) => {
		try {
			setLoading(true);
			const res = await api_deleteProduct(pId);
			console.log(res);
			if (res.isSuccess) {
				removeProductFromTable(pId);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<tr key={index}>
			<td>
				<div className='flex-shrink-0'>
					<div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
						<div className='ratio ratio-1x1 me-3 ' style={{ width: 50 }}>
							{item?.avatar ? (
								<Avatar src={getImageUrl(item?.avatar)} />
							) : (
								<div>
									<span className='fw-bold'>
										{item?.name ? getFirstLetter(item.name) : ''}
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</td>

			<td>
				<div className='d-flex'>
					<div className='flex-grow-1'>
						<div className='fs-6 fw-bold'>{item?.name}</div>
					</div>
				</div>
			</td>
			<td>
				<img
					src={getImageUrl(item?.image)}
					style={{
						width: 50,
						height: 50,
						borderRadius: 50,
						objectFit: 'cover',
					}}
				/>
			</td>

			<td>
				{item?.price?.prefix} {item?.price?.amount}
			</td>
			<td>
				{item?.local_price?.prefix} {item?.local_price?.amount}
			</td>
			<td>{item?.quantity}</td>
			<td>{item?.categories[0]?.name}</td>
			<td>
				<span
					onClick={() => navigate('/edit/product', { state: item })}
					style={{ margin: 10, cursor: 'pointer' }}>
					EDIT
				</span>
				<span
					onClick={() => handleDeleteProduct(item._id, index)}
					style={{ margin: 10, cursor: 'pointer' }}>
					{loading ? 'Loading..' : 'DELETE'}
				</span>
			</td>
		</tr>
	);
};

const AllProduct = () => {
	const [product, setProduct] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [pageNo, setPageNo] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const navigate = useNavigate();
	const tableBodyRef = useRef<HTMLTableSectionElement>(null);

	// fliters
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [filterCategoryId, setFilterCategoryId] = useState({ _id: '0', title: 'ALL' });

	const getProduct = async (page_no?: number, CId?: string) => {
		const finalPageNo = page_no || pageNo;
		const selectedCategoryId = filterCategoryId._id === '0' ? '' : filterCategoryId._id;
		const finalCId = CId === '' ? '' : selectedCategoryId;
		console.log('HERE -->', {
			finalPageNo,
			searchString,
			finalCId,
			selectedCategoryId,
		});
		try {
			setLoading(true);

			const res = await api_getProductsWithFilters(
				finalPageNo,
				PAGE_SIZE,
				searchString,
				finalCId,
			);
			console.log(res);
			if (res.isSuccess) {
				if (!res.data.length) {
					setHasMore(false);
					return;
				}

				if (page_no === 1) {
					setProduct(res.data);
					setPageNo(2);
				} else {
					setProduct([...product, ...res.data]);
					setPageNo(pageNo + 1);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const removeProductFromTable = (productId: string) => {
		setProduct(product.filter((i: any) => i._id !== productId));
	};
	const onLoadMore = () => {
		if (hasMore) {
			getProduct();
		}
	};

	const handleFilterReset = () => {
		setProduct([]);
		setHasMore(true);
		setFilterCategoryId({ _id: '0', title: 'ALL' });
		setSearchString('');
		getProduct(1, '');
	};

	useEffect(() => {
		getProduct();
	}, []);

	useEffect(() => {
		tableBodyRef.current?.addEventListener('scroll', (e) => {
			console.log(e);
		});
	}, []);
	return (
		<>
			{/* MODAL */}
			<Modal
				show={filterModalOpen}
				onHide={() => setFilterModalOpen(false)}
				keyboard={false}
				size='sm'
				aria-labelledby='contained-modal-title-vcenter'
				centered>
				<Modal.Header closeButton>
					<Modal.Title>Product Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='mb-3'>
						<label htmlFor='formFileSm' className='form-label'>
							Product Category
						</label>

						<SelectAsyncPaginate
							onChange={setFilterCategoryId}
							value={filterCategoryId}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setFilterModalOpen(false)}>Close</Button>
					<Button onClick={handleFilterReset}>Reset</Button>
				</Modal.Footer>
			</Modal>

			{/* MODAL */}
			<PageWrapper title={'Products'}>
				<div style={{ marginTop: '20px' }}>
					<SubHeader>
						<SubHeaderLeft>
							<label
								className='border-0 bg-transparent cursor-pointer me-0'
								htmlFor='searchInput'>
								<Icon
									onClick={() => {
										setProduct([]);
										setHasMore(true);
										getProduct(1);
									}}
									icon='Search'
									size='2x'
									color='primary'
								/>
							</label>
							<Input
								value={searchString}
								onChange={(e: any) => setSearchString(e?.target?.value)}
								id='searchInput'
								type='search'
								className='border-0 shadow-none bg-transparent'
								placeholder='Search order...'
							/>
						</SubHeaderLeft>
						<SubHeaderRight>
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									<Button
										onClick={() => setFilterModalOpen(true)}
										icon='FilterAlt'
										color='dark'
										isLight
										className='btn-only-icon position-relative'>
										<Popovers desc='Filtering applied' trigger='hover'>
											<span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
												<span className='visually-hidden'>
													there is filtering
												</span>
											</span>
										</Popovers>
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg'>
									<div className='container py-2'>
										<div className='row g-3'>
											{/* <FormGroup label='Balance' className='col-12'>
										<InputGroup>
											<Input
												id='minPrice'
												ariaLabel='Minimum price'
												placeholder='Min.'
												onChange={formik.handleChange}
												value={formik.values.minPrice}
											/>
											<InputGroupText>to</InputGroupText>
											<Input
												id='maxPrice'
												ariaLabel='Maximum price'
												placeholder='Max.'
												onChange={formik.handleChange}
												value={formik.values.maxPrice}
											/>
										</InputGroup>
									</FormGroup> */}
											<FormGroup label='Order Filter' className='col-12'>
												<ChecksGroup>
													<Checks
														key='1'
														id=''
														label='Active'
														name='Active'
														value={'true'}
														//  onChange={(e)=>handleFilterApi(e)}
														//  checked={selectedFilters.includes('true')}
													/>

													<Checks
														key='2'
														id=''
														label='Price(low to high)'
														name='Payment'
														value={'false'}
														//  onClick={(e)=>handleFilterApi(e)}
														//  checked={selectedFilters.includes('false')}
													/>
													<Checks
														key='2'
														id=''
														label='Price(high to low)'
														name='Payment'
														value={'false'}
														//  onClick={(e)=>handleFilterApi(e)}
														//  checked={selectedFilters.includes('false')}
													/>
													<Checks
														key='3'
														id=''
														label='Ratings'
														name='Ratings'
														value={'Ratings'}
														//  onClick={(e)=>handleFilterApi(e)}
														//  checked={selectedFilters.includes('ascending')}
													/>
													{/* <Checks
												 key='4'
												 id=""
												 label="Dscending"
												 name="Dscending"
												 value = {'dscending'}
												//  onClick={(e)=>handleFilterApi(e)}
												//  checked={selectedFilters.includes('dscending')}


											 /> */}
												</ChecksGroup>
											</FormGroup>
										</div>
									</div>
								</DropdownMenu>
							</Dropdown>
							<SubheaderSeparator />
						</SubHeaderRight>
					</SubHeader>
				</div>
				<Card stretch>
					<CardHeader borderSize={1}>
						<CardLabel icon='Alarm' iconColor='info'>
							<CardTitle>All Products</CardTitle>
						</CardLabel>
						<CardActions>
							<Button
								onClick={() => navigate('/add/product')}
								icon='PersonAdd'
								color='primary'
								isLight={true}>
								New Product
							</Button>
						</CardActions>
					</CardHeader>
					<CardBody isScrollable className='table-responsive'>
						<table className='table table-modern'>
							<thead>
								<tr>
									<td style={{ width: 60 }} />
									<th>Name </th>
									<th>Image</th>
									<th>Global Price</th>
									<th>Local Price</th>
									<th>Qty</th>
									<th>Category</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{product?.map((item: any, index: any) => {
									return (
										<Item
											removeProductFromTable={removeProductFromTable}
											key={index}
											item={item}
											index={index}
										/>
									);
								})}
							</tbody>
						</table>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							{hasMore && (
								<button
									style={{
										border: 'none',
										padding: '10px',
										borderRadius: '10px',
									}}
									onClick={onLoadMore}>
									Load More...
								</button>
							)}
						</div>
					</CardBody>
				</Card>
			</PageWrapper>
		</>
	);
};

export default AllProduct;
