import { useState } from 'react';
import { api_createCategories } from '../../../api/category';
import { useNavigate } from 'react-router-dom';
import { api_userCreate } from '../../../api/user';
import { myToastWarning } from '../../../helpers/myToast';
import { toast } from 'react-hot-toast';

// {
//     "role": "admin",
//     "sex": "male",
//   }

const GENDERS = ['male', 'female'];
const ROLE = ['customer', 'admin'];

const AddUser = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [gender, setGender] = useState(GENDERS[0]);
	const [role, setRole] = useState(ROLE[0]);
	const [email, setEmail] = useState('');
	const [address, setAddress] = useState('');
	const navigate = useNavigate();
	const create = async () => {
		if (!email) {
			myToastWarning('Please Enter email');
			return;
		}
		if (!name) {
			myToastWarning('Please Enter Name');
			return;
		}
		if (!password) {
			myToastWarning('Please Enter Password');
			return;
		}

		try {
			setLoading(true);
			const payload = {
				name: name,
				email: email,
				password: password,
				role: role,
				// dob: 'string',
				sex: gender,
				address: address,
				is_activited: true,
			};
			const res = await api_userCreate(payload);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setTimeout(() => {
					navigate(-1);
				}, 100);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Add User</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Name
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Email
				</label>
				<input
					value={email}
					type='email'
					onChange={(e) => setEmail(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Password
				</label>
				<input
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div style={{ width: '400px' }}>
				<div className='mb-3'>
					<label htmlFor='exampleFormControlInput1' className='form-label'>
						Gender
					</label>
					<select
						onChange={(e) => setGender(e.target.options[e.target.selectedIndex].value)}
						className='form-select'
						aria-label='Default select example'>
						<option disabled>Choose Gender</option>

						{GENDERS.map((i, index) => {
							return (
								<option key={index} value={i}>
									{i}
								</option>
							);
						})}
					</select>
				</div>

				<div className='mb-3'>
					<label htmlFor='exampleFormControlInput1' className='form-label'>
						Role
					</label>
					<select
						onChange={(e) => setRole(e.target.options[e.target.selectedIndex].value)}
						className='form-select'
						aria-label='Default select example'>
						<option disabled>Choose Role</option>
						{ROLE.map((r, index) => (
							<option key={index} value={r}>
								{r}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Address
				</label>
				<textarea
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div>
			<button
				onClick={create}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default AddUser;
