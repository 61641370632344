import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api_createProduct, api_editProduct } from '../../../api/product';
import { SelectAsyncPaginate } from './AddProduct';
import { toast } from 'react-hot-toast';
import { myToastWarning } from '../../../helpers/myToast';

const EditProduct = () => {
	const product = useLocation().state;
	console.log(product, 'edit');
	const [loading, setLoading] = useState<boolean>(false);
	const [image, setImage] = useState<any>(null);
	const [name, setName] = useState(product.name || '');
	const [quantity, setQuantity] = useState<string | number>(product.quantity || '1');
	const [price, setPrice] = useState<string | number>(product?.price?.amount || '1');
	const [localPrice, setLocalPrice] = useState<string | number>(
		product?.local_price?.amount || '1',
	);
	const [des, setDes] = useState(product.description || '');
	const [address, setAddres] = useState(product.address || '');
	const navigate = useNavigate();
	const [selectedCategory, setSelectedCategory] = useState<any>(null);

	console.log(selectedCategory);
	const edit = async () => {
		console.log(image);
		// if (image === null) {
		// 	alert('Please Pick Image');
		// 	return;
		// }
		if (!quantity) {
			myToastWarning('Please Enter Quantity');
			return;
		}
		if (!name) {
			myToastWarning('Please Enter Name');
			return;
		}
		try {
			console.log(Number(localPrice));
			console.log(Number(price));
			setLoading(true);
			const payload = new FormData();
			payload.append('name', name);
			payload.append('description', des);
			// @ts-ignore
			payload.append('quantity', quantity);
			//GLOBAL -> PRICE
			// @ts-ignore
			payload.append('price_amount', Number(price));
			payload.append('price_prefix', '$');
			payload.append('price_currency', 'globaly');

			//LOCAL -> PRICE
			// @ts-ignore
			payload.append('local_price_amount', Number(localPrice));
			payload.append('local_price_prefix', 'Br');
			payload.append('local_price_currency', 'local');

			payload.append('address', '');
			// payload.append('price_prefix','$')
			if (selectedCategory !== null) {
				payload.append('category', selectedCategory._id);
			}
			payload.append('image', image);
			const res = await api_editProduct(payload, product._id);
			console.log(res);
			if (res.isSuccess) {
				toast.success(res.message || 'success');
				setTimeout(() => {
					navigate(-1);
				}, 100);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				alignItems: 'center',
				flex: 1,
				padding: '40px',
			}}>
			<div className='mb-3'>
				<h3>Edit Product</h3>
			</div>

			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Name
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Price In Dollar
				</label>
				<input
					value={price}
					onChange={(e) => setPrice(Number(e.target.value))}
					type='number'
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Price In Ethiopian Birr
				</label>
				<input
					value={localPrice}
					type='number'
					onChange={(e) => setLocalPrice(Number(e.target.value))}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Qty
				</label>
				<input
					value={quantity}
					type='number'
					onChange={(e) => setQuantity(Number(e.target.value))}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlInput1'
					placeholder=''
				/>
			</div>
			<div className='mb-3'>
				<label htmlFor='formFileSm' className='form-label'>
					Product Image
				</label>
				<input
					onChange={(event) => {
						// @ts-ignore

						console.log(event.target.files[0]);
						// @ts-ignore

						setImage(event.target.files[0]);
					}}
					style={{ width: '400px' }}
					className='form-control form-control-sm'
					id='formFileSm'
					type='file'
				/>
			</div>
			<div className='mb-3' style={{ width: '400px' }}>
				<label htmlFor='formFileSm' className='form-label'>
					Product Category
				</label>

				<SelectAsyncPaginate onChange={setSelectedCategory} value={selectedCategory} />
			</div>
			{/* <div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Address
				</label>
				<textarea
					value={address}
					onChange={(e) => setAddres(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div> */}
			<div className='mb-3'>
				<label htmlFor='exampleFormControlTextarea1' className='form-label'>
					Description
				</label>
				<textarea
					value={des}
					onChange={(e) => setDes(e.target.value)}
					style={{ width: '400px' }}
					className='form-control'
					id='exampleFormControlTextarea1'
					rows={3}></textarea>
			</div>
			<button
				onClick={edit}
				type='button'
				className='btn btn-lg btn-primary'
				disabled={loading}>
				{loading ? 'Loading..' : 'Submit'}
			</button>
		</div>
	);
};

export default EditProduct;
