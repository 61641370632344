import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
// import {BASE_URL} from '../../../API';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { Email } from '../../../components/icon/material-icons';
// import { json } from 'node:stream/consumers';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import background from '../../../assets/img/fruit/new1.png';
import { api_adminLogin } from '../../../api/auth';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser, setUserData } = useContext(AuthContext);
	const [formData, setFormData] = useState({
		name: '',
		company: '',
		email: '',
		password: '',
	});

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const moodIdd = '64f9c09a931b0b27da1689b2';
	const navigate = useNavigate();
	// const handleOnClick = useCallback(() => {
	// 	let fieldEmpty = {
	// 	  name: false,
	// 	  company: false,
	// 	  email: false,
	// 	  password: false,
	// 	};

	// 	let errorMessages = {
	// 	  name: "",
	// 	  company: "",
	// 	  email: "",
	// 	  password: "",
	// 	};

	// 	if (formData.name.trim() === "") {
	// 	  fieldEmpty.name = true;
	// 	  errorMessages.name = "Please fill in the name field.";
	// 	}

	// 	if (formData.company.trim() === "") {
	// 	  fieldEmpty.company = true;
	// 	  errorMessages.company = "Please fill in the company name field.";
	// 	}

	// 	if (formData.email.trim() === "") {
	// 	  fieldEmpty.email = true;
	// 	  errorMessages.email = "Please fill in the email field.";
	// 	} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
	// 	  fieldEmpty.email = true;
	// 	  errorMessages.email = "Please enter a valid email address.";
	// 	}

	// 	if (formData.password.trim() === "") {
	// 	  fieldEmpty.password = true;
	// 	  errorMessages.password = "Please fill in the password field.";
	// 	} else if (formData.password.length < 8) {
	// 	  fieldEmpty.password = true;
	// 	  errorMessages.password = "Password must be at least 8 characters long.";
	// 	}

	// 	if (
	// 	  !fieldEmpty.name &&
	// 	  !fieldEmpty.company &&
	// 	  !fieldEmpty.email &&
	// 	  !fieldEmpty.password
	// 	) {
	// 	  handleSubmit();
	// 	} else {
	// 	  console.log(fieldEmpty);
	// 	  console.log(errorMessages);
	// 	  // You can handle the error messages and display them in your UI
	// 	}
	//   }, [formData]);
	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};
	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		console.log(formData);
		try {
			const response = await fetch('https://nest.fringale.one/users/create', {
				method: 'POST',
				body: JSON.stringify(formData),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await response.json();
			console.log(data, 'data');
			setSingUpStatus(false);
			// navigate('/auth-pages/login'); // Navigate to the login page
		} catch (error) {
			console.log(error);
		}
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginUsername);
					}
					navigate('/');
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleContinue = async () => {
		if (!email) {
			alert('Please Enter email');
			return;
		}
		if (!password) {
			alert('Please Enter password');
			return;
		}

		setIsLoading(true);
		try {
			const res = await api_adminLogin(email, password);
			console.log(res, 'here ');
			if (res.isSuccess) {
				localStorage.setItem('AUTH', JSON.stringify(res.data));
				setUserData(res?.data);
				setUser(res.data?.name || '');
				navigate('/');
			} else {
				alert('Incorrect email or password');
			}
			setIsLoading(false);
		} catch (error: any) {
			console.log(error);
			alert(error.message);
			setIsLoading(false);
		}
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}
			background={background}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/dashboard'
										className={classNames(
											'text-decoration-none  fw-bold display-2',

											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<div className='logo-img'>
											<img src='' alt='' />
										</div>
										<Logo width={200} />
									</Link>
								</div>
								{/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} /> */}

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup id='name' isFloating label=' name'>
													<Input
														type='text'
														name='name'
														autoComplete='name'
														value={formData.name}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='company' isFloating label='company'>
													<Input
														type='text'
														name='company'
														autoComplete='company'
														value={formData.company}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='email' isFloating label='email'>
													<Input
														type='email'
														name='email'
														autoComplete='email'
														value={formData.email}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label='Password'>
													<Input
														type='password'
														name='password'
														autoComplete='new-password'
														value={formData.password}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button color='info' className='w-100 py-3'>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														type='email'
														name='email'
														autoComplete='email'
														value={email}
														onChange={(e: any) =>
															setEmail(e.target.value)
														}
													/>
												</FormGroup>

												{/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}  */}
												<br />
												<FormGroup
													id='loginPassword'
													isFloating
													label='Your Password'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														type='password'
														name='password'
														autoComplete='new-password'
														value={password}
														onChange={(e: any) =>
															setPassword(e.target.value)
														}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={handleContinue}>
													Login
												</Button>
											</div>
										</>
									)}

									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
