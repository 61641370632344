import { toast } from 'react-hot-toast';

export const myToastWarning = (msg: string) => {
	toast.error(msg, {
		style: {
			border: '1px solid orange',
		},
		iconTheme: {
			primary: 'orange',
			secondary: 'white',
		},
	});
};
